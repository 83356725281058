import React from "react"

import { BackgroundVideo } from "../components/BackgroundVideo"
import { BaseLayout } from "../components/BaseLayout"
import { Heading } from "../components/Heading"
import { Nav } from "../components/Nav"
import { Donate } from "../components/Donate"
import { Shortly } from "../components/Shortly"
import { SeoHead } from "../components/SeoHead"

export default function Home() {
  return (
    <BaseLayout>
      <SeoHead />
      <Nav />
      <Heading isPrimary />
      <BackgroundVideo />
      <Shortly />
      <Donate />
    </BaseLayout>
  )
}
