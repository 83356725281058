import React, { useCallback, useMemo, useRef, useState } from "react"
import { motion } from "framer-motion"

import styled from "styled-components"

import { Copy } from "./Copy"
import { CONTENT } from "../contants"

const Icon = props => (
  <svg
    viewBox="0 0 511.999 511.999"
    width={48}
    height={48}
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M326.679 221.34c21.901 18.576 24.683 21.158 34.321 29.458 7.062-6.057 13.121-11.567 38.804-33.105C446.942 178.112 481 149.533 481 107.317c0-40.02-29.399-71.367-66.929-71.367-25.21 0-41.938 14.224-52.441 28.711-9.946-14.531-26.118-28.711-51.401-28.711-37.529 0-66.914 31.348-66.914 71.367-.001 43.286 33.164 71.425 83.364 114.023zM85.8 467.949c39.3-26.699 82.301-36.901 129.101-29.1l75 12.9c28.801 4.801 55.199 3.3 77.999-4.2 47.168-15.72 94.387-65.229 106.901-84 17.401-26.1 26.96-54.6 32.961-68.399 5.7-13.2 5.7-22.5-.3-27.9-6.901-6.301-18.6-3.001-27.301 1.8-13.799 7.8-28.46 30.3-43.46 51.899-19.499 28.2-52.899 45-87.1 45H241v-30h45c79.799 0 115.499-15 115.499-30 0-12.599-24.899-18.3-33.3-20.099-32.699-7.5-106.5-11.1-156.899-33.6-9.089-3.977-19.393-6.209-30.298-6.301-17.098-.145-35.68 5.182-53.403 18.9-.298 0-.298 0-.599.3-11.7 9-27.7 22.2-49.3 41.1-18.301 16.199-36 36.899-66.901 63.3l-10.8 9.3L73.8 476.05z" />
  </svg>
)

export const Donate = props => {
  // const isMobile = useMemo(
  //   () =>
  //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //       window.navigator.userAgent
  //     ),
  //   [window.navigator.userAgent]
  // )
  const [view, setView] = useState(null)
  const donateRef = useRef()

  const onShowDonate = useCallback(() => {
    if (donateRef?.current) {
      donateRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    }
  }, [donateRef])

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 2, delay: 1.8 }}
    >
      <MobileButton onClick={onShowDonate}>
        <MobilDonateIcon />
        <span>Donate to support the victims</span>
      </MobileButton>
      <Wrapper id="donate" ref={donateRef}>
        <DonateWrapper>
          <Copy display="h2" color="white" className="desktop-only">
            {CONTENT.completed
              ? "This donation has been completed !"
              : "Donate to support the victims"}
            <DonateIcon />
          </Copy>
          {CONTENT.fundsRaised && (
            <Copy display="detail" center className="fundsRaised">
              Funds raised
              <br />
              <Copy display="h1" color="sand" type="div">
                {CONTENT.fundsRaised.toLocaleString("it")} €
              </Copy>
            </Copy>
          )}
        </DonateWrapper>
        {CONTENT.completed && (
          <div style={{ margin: "24px 0", textAlign: "center" }}>
            <Copy display="body" color="white">
              You can donate to support the victims of Barda:{" "}
              <a
                href="https://prayforbarda.com"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                www.prayforbarda.com
              </a>
            </Copy>
          </div>
        )}
        {/* {view === "bank" && CONTENT.id === "ganja" && (
          <Content id="donateblock">
            <Copy display="h3" color="white" type="p" thin className="method">
              <Copy display="label" color="grey" type="p" thin>
                Transfer with IBAN (EUR)
              </Copy>
              NL19 INGB 0666 3518 48
              <br />
              (Name: S. Mustafayev)
            </Copy>
            <Copy display="h3" color="white" type="p" thin className="method">
              <Copy display="label" color="grey" type="p" thin>
                Transfer with credit card number (AZN)
              </Copy>
              5243 7544 1062 5675
              <br />
              (Name: Sanan Quliyev)
            </Copy>
          </Content>
        )} */}
        {/* {CONTENT.id === "ganja" && (
          <LinksWrapper>
            <Link
              href="https://www.ing.nl/particulier/betaalverzoek/index.html?trxid=9ItGOS4JOM5l9AmDxpeXsLVlDG2dvqRG"
              target="_blank"
            >
              NL & BE
            </Link>
            <Link href="https://paypal.me/pools/c/8tppgBIaBz" target="_blank">
              PayPal
            </Link>
            <Link
              href="https://www.facebook.com/donate/723153495213624"
              target="_blank"
            >
              Facebook Donate
            </Link>
            <Link
              href="#donateblock"
              onClick={() => setView("bank")}
              active={view === "bank"}
            >
              Bank transfer
            </Link>
            <Link href="mailto:contact@prayforganja.com">Contact Us</Link>
          </LinksWrapper>
        )} */}
        {view === "bank" && CONTENT.id === "barda" && (
          <Content id="donateblock">
            <Copy display="h3" color="white" type="p" thin className="method">
              <Copy display="label" color="grey" type="p" thin>
                Transfer with IBAN - EUR (for outside NL and BE)
              </Copy>
              NL19 INGB 0666 3518 48
              <br />
              (Name: S. Mustafayev)
            </Copy>
            <Copy display="h3" color="white" type="p" thin className="method">
              <Copy display="label" color="grey" type="p" thin>
                Transfer with credit card number (AZN)
              </Copy>
              5103 0714 2587 1452
              <br />
              (Name: Sanan Suleymanli, Kapital Bank)
            </Copy>
          </Content>
        )}
        {CONTENT.id === "barda" && (
          <LinksWrapper>
            <Link
              href="https://www.ing.nl/particulier/betaalverzoek/index.html?trxid=fB0RFY3yTUsmlmcVSsuzJPRV1jQy4v9n"
              target="_blank"
            >
              NL & BE
            </Link>
            <Link
              href="https://www.paypal.com/pools/c/8tSbkNsL8z"
              target="_blank"
            >
              PayPal
            </Link>
            <Link
              href="https://www.facebook.com/donate/402521537789730"
              target="_blank"
            >
              Facebook Donate
            </Link>
            <Link
              href="#donateblock"
              onClick={() => setView("bank")}
              active={view === "bank"}
            >
              Bank transfer
            </Link>
            <Link href="mailto:contact@prayforganja.com">Contact Us</Link>
          </LinksWrapper>
        )}
      </Wrapper>
    </motion.div>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;

  .desktop-only {
    transform: translate(0, -10px);
  }

  ${props => props.theme.isMobile} {
    min-height: 0;
    background-color: #4caf50;
    width: 100%;
    padding: 0 32px;
    margin-left: -32px;
    position: absolute;
    top: 100%;

    .fundsRaised {
      color: white !important;
      margin-top: 24px;

      div {
        font-size: 48px !important;
        color: white !important;
      }
    }

    .desktop-only {
      display: none;
    }
  }
`

const LinksWrapper = styled.div`
  display: flex;
  margin: 24px auto;

  ${props => props.theme.isMobile} {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
`
const Link = styled.a`
  background-color: ${props =>
    props.active ? props.theme.colors.grey : props.theme.colors.grey2};
  color: ${props => props.theme.colors.secondary};
  color: white;
  border: 2px solid transparent;
  transform: none;

  padding: 12px 16px;
  border-radius: 8px;
  margin: 0 12px;
  text-decoration: none;

  &:hover {
    background-color: ${props => props.theme.colors.white};
    color: black;
  }

  ${props => props.theme.isMobile} {
    border: 2px solid white;
    background-color: transparent;
    margin: 0 8px;
    margin-bottom: 16px;
    flex-grow: 1;
    text-align: center;
  }
`

const DonateIcon = styled(Icon)`
  padding: 8px;
  transform: translate(0, 10px) scale(1.3);
  margin: 0 8px;
  fill: #cdceae;
`

const Content = styled.div`
  color: white;
  margin: 32px 0;

  .method {
    margin-bottom: 24px;
  }
`

const MobileButton = styled.button`
  padding: 16px 10px;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #42a446;
  color: white;
  font-size: 18px;
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  outline: none;
  margin: 0;

  ${props => props.theme.isMobile} {
    display: flex;
  }
`

const MobilDonateIcon = styled(Icon)`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  fill: white;
`

const DonateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
