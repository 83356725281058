import React from "react"
import styled from "styled-components"

import { motion } from "framer-motion"
import { CONTENT } from "../contants"

export const BackgroundVideo = props => {
  return (
    <Wrapper animate={{ opacity: 0.3 }} transition={{ duration: 3 }}>
      {CONTENT.id === "ganja" && (
        <video
          src={require("../../static/video.mp4")}
          playsInline
          autoPlay
          muted
          loop
        />
      )}
      {CONTENT.id === "barda" && (
        <Image src={require("../../static/barda.jpg")} />
      )}
    </Wrapper>
  )
}

const Image = styled.div`
  width: 100%;
  height: 100%;
  background: url("${props => props.src}") center no-repeat;
  background-size: cover;
`

const Wrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 800px;
  top: -100px;
  left: 0;
  opacity: 0;
  filter: grayscale(1);

  video {
    width: 100%;
    height: 100%;
  }

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    background: rgb(0, 0, 0);
    background: radial-gradient(
      35.99% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 0%,
      #000000 100%
    );
  }

  ${props => props.theme.isMobile} {
    line-height: 0.8;
    font-size: 58px;
    height: 100vh;
    width: 100%;
    left: 0;

    video {
      object-fit: cover;
    }
  }
`
