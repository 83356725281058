import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useCountUp } from "react-countup"

import { Copy } from "./Copy"
import { CONTENT } from "../contants"

export const Shortly = () => {
  const killed = useCountUp({ end: CONTENT.killed, duration: 5, delay: 1 })
  const wounded = useCountUp({ end: CONTENT.wounded, duration: 5, delay: 1 })

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 2, delay: 1 }}
    >
      <Wrapper>
        <Title display="div" type="div">
          <Copy
            display="display"
            color="darkAccent"
            type="div"
            className="number"
          >
            {killed.countUp}
          </Copy>
          <Copy display="h1" color="white">
            Civilians Killed
          </Copy>
        </Title>
        <Title display="div" type="div">
          <Copy
            display="display"
            color="darkAccent"
            type="div"
            className="number"
          >
            {wounded.countUp}
          </Copy>
          <Copy display="h1" color="white">
            Civilians Wounded
          </Copy>
        </Title>
      </Wrapper>
    </motion.div>
  )
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 64px;
  justify-content: center;

  .number {
    width: 117px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  ${props => props.theme.isMobile} {
    position: absolute;
    top: calc(100% - 150px);
    left: -8px;
    margin-bottom: 0px;
    width: 100%;

    .number {
      width: 97px;
      font-size: 72px;
    }
  }

  @media (max-width: 600px) {
    transform: scale(0.7);
  }

  @media (max-width: 375px) {
    transform: scale(0.6);
  }
`

const Title = styled(Copy)`
  width: 230px;
  margin-right: 64px;
  transform: skew(-3deg, 0);
  display: flex;
  margin-bottom: auto;
  align-items: center;

  ${props => props.theme.isMobile} {
    margin-bottom: 16px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
`
